<template>
  <PageNotFound />
</template>

<script>
import PageNotFound from '@/components/pages/PageNotFound'

export default {
  components: {
    PageNotFound
  }
}
</script>
